<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center" style="padding-top:20px">
    <v-dialog
      v-model="dialog"
     max-width="600px"
    >
      <template>

      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
            <v-row style="margin-top:15px">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label>Type *</label>
                <select
                  v-model=stock.type
                  class="form-control"
                ><option v-for="type in types" v-bind:key="type.id" :value="stock.type">{{type.name}}</option></select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label>Quantité *</label>
                <input
                  label="Quantité *"
                  required
                  v-model=stock.quantity
                  placeholder="Quantité *"
                  class="form-control"
                >
              </v-col>
              
              <v-col
                cols="12"
                sm="12"
              >
              <label>Coût *</label>
              <input
                  label="Coût *"
                  required
                  v-model=stock.cost
                  placeholder="Coût *"
                  class="form-control"
                >
                
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
              <label>Mémo</label>
                <input
                  label="Mémo *"
                  required
                  v-model=stock.memo
                  placeholder="Mémo"
                  class="form-control"
                >
              </v-col>
            </v-row>

      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher un Produit"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item.cost="{ item }">
        {{ formatNumber(item.cost) }}
    </template>
    <template v-slot:item.type="{ item }">
        {{ types[item.type].name }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
        v-if=item.type
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        class="mr-2 delete_button"
        @click="erase(item)"
        v-if=item.type
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import numeral from 'numeral'

export default {
  name: 'OperationsView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/operations?include=product&from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          self.rows = response.data.result
          self.enable = true          
        })
    },
      save: function(){
        var self = this
        this.loading = true
        var url = ''
        if (this.index > -1) { 
          url = '/operations/'+this.stock.id
          this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.stock)))
          .then(response => {
            Object.assign(self.rows[self.index], response.data)
            self.recalculateStock()
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }
      },
      formatNumber: function(cost){
      return numeral(cost).format('0,0.00')
    },
    erase: function(item){
      var self = this
      this.loading = true
      var url = ''
      this.index = this.rows.indexOf(item)
      url = '/operations/'+item.id
      this.$axios.delete(url)
        .then(response => {
          console.log(response)
          self.rows.pop(self.index)
          self.recalculateStock()
          self.dialog = false
          self.loading = false
          self.reset()
        })
    },
    recalculateStock: function(){
      var self = this
        this.loading = true
        var url = ''
        if (this.index > -1) { 
          url = '/operations/refactor'
          this.$axios.post(url)
          .then(response => {
            console.log(response)
            self.load()
          })
        }
    },
    edit: function(item){
      this.index = this.rows.indexOf(item)
      this.stock = Object.assign({}, item)
      this.form_title = "Editer Opération : "+this.stock.product_name
      this.dialog = true
    },
    close: function(){
      this.dialog = false
      this.reset()
      this.loading = false
    },
    reset: function(){
      this.stock = {
        product_id: '',
        id:'',
        user_id: '',
        cost: '',
        memo: '',
        type: 1,
        quantity: '',
      }
      this.index = -1
      this.validate_name = true
      this.form_title = 'Nouvelle Op♪0ration'
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    },
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      index: -1,
      loading: false,
      types: [{name: "Vente", id: 0}, {name: "Réception", id: 1}, {name: 'Ajustement', id: 2}, {name: 'Autre', id: 3}],
      form_title: "Nouvelle Opération",
      items: [
        {
          text: 'Opérations',
          disabled: false,
          href: '/operations',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: 'CATEGORIE',
            align: 'start',
            sortable: false,
            value: 'category_name',
          },
          { text: 'PRODUIT', value: 'product_name' },
          { text: 'TYPE', value: 'type' },
          { text: 'QUANTITE', value: 'quantity' },
          { text: 'DATE', value: 'transaction_date' },
          { text: 'MEMO', value: 'memo' },
          { text: 'UTILISATEUR', value: 'username' },
          { text: 'ACTIONS', value: 'actions' },
        ],
        rows: [],
        roles: [],
        dialog:'',
        dialogDelete: false,
        stock : {
          product_id: '',
          user_id: '',
          cost: '',
          id:'',
          memo: '',
          type: 1,
          quantity: '',
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .v-application--wrap .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .v-dialog button.v-btn{
    margin-right:8px!important;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:18px;
  }

   .v-application .v-dialog .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:0px!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }c
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>